import React from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import { hero, heroText, mobileHero, mobileHeroText } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="100vh"
          w="100%"
          maxHeight="800px"
          image={`url(${mobileHero}) bottom / cover no-repeat`}
          column
          alignCenter
        >
          <CFView textCenter w="100%">
            <CFView mt="15px">
              <CFImage
                w="90%"
                src={mobileHeroText}
                alt="Sushi Mi Bar hero text"
                zIndex={98}
              />
            </CFView>
            <CFView mv="15px" pulsate>
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="100vh"
          maxheight="700px"
          m="0 auto"
          maxWidth="1400px"
          image={`url(${hero}) left / cover no-repeat`}
          zIndex={90}
          column
          justifyStart
          alignStart
        >
          <CFView
            column
            justifyCenter
            alignStart
            ml="65px"
            mt="20px"
            h="100vh"
            maxheight="700px"
          >
            <CFView column justifyCenter alignStart>
              <CFImage
                w="470px"
                src={heroText}
                alt="Sushi Mi Bar hero text"
                zIndex={98}
              />
            </CFView>
            <CFView mv="40px" pulsate>
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
